import { Button, Menu, MenuItem, Tooltip } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { determineInitials } from "../../utils/initialUtils";
import { makeStyles } from "@mui/styles";
import { zebStyles as parentZebStyles } from "../../zebstyles";
import { useAuth } from "react-oidc-context";
import { useIntl } from "react-intl";
import Avatar from "@mui/material/Avatar";
import PersonIcon from "@mui/icons-material/Person";
import React, { useState } from "react";
import RolesDialog from "./RolesDialog";

const theme = createTheme();
const useStyles = makeStyles(() => ({
  ...parentZebStyles(theme),
  initial: {
    width: 40,
    height: 40,
    fontSize: 15,
    textColor: theme.palette.text.secondary,
    color: theme.palette.grey[100],
    marginLeft: "auto",
    marginRight: "auto",
  },
}));

export default function UserProfile() {
  const { formatMessage } = useIntl();
  const auth = useAuth();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogin = () => {
    auth !== null && auth.signinRedirect();
  };

  const handleLogout = () => {
    if (auth !== null) {
      auth.signoutRedirect();
      auth.removeUser();
    }
  };

  const [showRolesDialog, setShowRolesDialog] = useState<boolean>(false);
  const handleShowRolesDialog = (): void => {
    setShowRolesDialog(true);
  };

  function username(): string {
    return auth && auth.user && auth.user.profile && auth.user.profile.name ? auth.user.profile.name : "";
  }

  return (
    <div>
      <Tooltip title={username() != "" ? username() : formatMessage({ id: "pleaselogin.message" })}>
        <Button aria-controls="user-menu" aria-haspopup="true" onClick={handleOpen}>
          <Avatar alt={username()} className={classes.initial}>
            {username() != "" ? determineInitials(username()) : <PersonIcon />}
          </Avatar>
        </Button>
      </Tooltip>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}>
        {username() != "" ? (
          <div>
            <MenuItem onClick={handleShowRolesDialog}>{formatMessage({ id: "userMenu.roles" })}</MenuItem>
            <MenuItem onClick={handleLogout}>{formatMessage({ id: "userMenu.logout" })}</MenuItem>
          </div>
        ) : (
          <MenuItem onClick={handleLogin}>{formatMessage({ id: "userMenu.login" })}</MenuItem>
        )}
      </Menu>
      <RolesDialog open={showRolesDialog} onClose={() => setShowRolesDialog(false)} />
    </div>
  );
}
