import { Box, Dialog, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { useAuth } from "react-oidc-context";
import { useIntl } from "react-intl";

type Props = {
  open: boolean;
  onClose: () => void;
};

const RolesDialog = ({ open, onClose }: Props) => {
  const auth = useAuth();

  const { formatMessage: f } = useIntl();

  const roles = (auth.user?.profile.roles as string[]) ?? [];

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ mb: 2 }}>
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
          <Typography variant="h5">
            {f({ id: "userMenu.roles" })} ({roles.length})
          </Typography>
          <IconButton onClick={onClose}>
            <CloseOutlined />
          </IconButton>
        </Box>
        <Typography>{f({ id: "rolesDialog.title" })}</Typography>
      </DialogTitle>
      <DialogContent>
        {!roles ? (
          <Typography>{f({ id: "rolesDialog.noRoles" })}</Typography>
        ) : (
          roles.map((item) => <Typography key={item}>{item}</Typography>)
        )}
      </DialogContent>
    </Dialog>
  );
};
export default RolesDialog;
