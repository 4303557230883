import { hasAuthParams, useAuth } from "react-oidc-context";
import { useEffect, useState } from "react";

const AuthGuard = ({ children }: { children: JSX.Element }): JSX.Element => {
  const auth = useAuth();
  const [hasTriedSignin, setHasTriedSignin] = useState<boolean>(false);

  // automatically sign-in
  useEffect(() => {
    if (!hasAuthParams() && !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading && !hasTriedSignin) {
      auth.signinRedirect();
      setHasTriedSignin(true);
    }
  }, [auth, hasTriedSignin]);

  return children;
};

export default AuthGuard;
