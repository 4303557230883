import { HeadCell, Order, getComparator } from "./utilFileTable";

import { CircularProgress, Link, Tooltip } from "@mui/material";
import { S3Object } from "../../api/restModel";
import { createTheme } from "@mui/material/styles";
import { formatErrorToMessageId } from "../../api/errorUtil";
import { getDownloadUrl, getFolders, multiPartUpload, uploadFile } from "../../api/restFacade";
import { isDownloadFileAllowed, isUploadFileAllowed } from "../../api/authorizationSettings";
import { useAuth } from "react-oidc-context";
import { useDropzone } from "react-dropzone";
import { useIntl } from "react-intl";
import { useSnackbar } from "notistack";
import { visuallyHidden } from "@mui/utils";

import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import ConfirmDialog from "../../dialogs/ConfirmDialog";
import DeleteFileButton from "./buttons/DeleteFileButton";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import React, { useEffect, useState } from "react";

import Skeleton from "@mui/material/Skeleton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Typography from "@mui/material/Typography";
import UploadButton from "./buttons/UploadButton";
import handleUpload, { fileAndResponse } from "./util/UploadUtil";
import useAPIError from "../../dialogs/error/useAPIError";
import useCurrentFolder from "../../contexts/useCurrentFolder";
import useLoadingFiles from "../../contexts/useLoadingFiles";

import MoveFileButton from "./buttons/MoveFileButton";
import zebTheme from "../../theme/zebTheme";

const headCells: readonly HeadCell[] = [
  {
    id: "s3Key",
    numeric: false,
    disablePadding: true,
    label: "tableHeader.name",
  },
  {
    id: "fileType",
    numeric: false,
    disablePadding: false,
    label: "tableHeader.fileType",
  },
  {
    id: "size",
    numeric: true,
    disablePadding: false,
    label: "tableHeader.fileSize",
  },
  {
    id: "lastModified",
    numeric: false,
    disablePadding: false,
    label: "tableHeader.lastModified",
  },
];

interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof S3Object) => void;
  handleParentCheckboxChange: (event: any) => void;
  order: Order;
  orderBy: string;
  numberOfSelectedRows: number;
  numberOfRows: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, numberOfRows, numberOfSelectedRows, onRequestSort, handleParentCheckboxChange } = props;
  const createSortHandler = (property: keyof S3Object) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  const { formatMessage } = useIntl();

  return (
    <TableHead>
      <TableRow>
        <TableCell sx={{ backgroundColor: "#FFF", borderBottom: "none" }}>
          <Checkbox
            id="parent"
            checked={numberOfRows == numberOfSelectedRows && numberOfRows > 0}
            indeterminate={numberOfSelectedRows > 0 && numberOfRows != numberOfSelectedRows}
            onChange={handleParentCheckboxChange}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            sx={{ backgroundColor: "#FFF", borderBottom: "none" }}
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}>
              {formatMessage({ id: headCell.label })}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface FileTableProps {
  currentFolder: string;
  setCurrentFolder(currentFolder: string): void;
  currentFolderName: string;
  folderMap: Map<string, S3Object[]>;
  setFolderMap(map: Map<string, S3Object[]>): void;
  updateFolderMap(key: string, value: S3Object[]): void;
}

const currentLanguage = navigator.language.split(/[-_]/)[0];

let isConfirmDialogCheckboxChecked = false;
const filesAndResponses = new Array<fileAndResponse>();

export default function FileTable(props: FileTableProps) {
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof S3Object>("s3Key");
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState<S3Object[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [selectedRows, setSelectedRows] = React.useState(new Set<S3Object>());
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openMoveDialog, setOpenMoveDialog] = useState<boolean>(false);
  const [rowsLoadingIndicator, setRowsLoadingIndicator] = useState<boolean>(false);
  const [fileToUpload, setFileToUpload] = useState<File>();

  const { addError } = useAPIError();
  const { formatMessage } = useIntl();
  const auth = useAuth();
  const { addLoadingFile, removeLoadingFile, updateFileWithUploadProgress, setDownloadingIndicator } = useLoadingFiles();
  const { enqueueSnackbar } = useSnackbar();
  const { getCurrentFolderS3Object } = useCurrentFolder();

  const handleConfirmDialogCheckboxChange = (): void => {
    isConfirmDialogCheckboxChecked = !isConfirmDialogCheckboxChecked;
  };

  const onDrop = (acceptedFiles: any) => {
    combineFileUploadFromDiffrentSources(acceptedFiles);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop,
    disabled: !isUploadFileAllowed(getCurrentFolderS3Object(props.currentFolder)),
    multiple: true,
  });

  useEffect(() => {
    const currentFolder = props.currentFolder === "" ? "/" : props.currentFolder;
    if (props.folderMap.get(currentFolder)) {
      const currentRows = props.folderMap.get(currentFolder);

      if (currentRows) {
        setRows(currentRows.filter((row) => row.isFile));
        if (!openMoveDialog) {
          setSelectedRows(new Set<S3Object>());
        }
      } else {
        setRows([]);
        setSelectedRows(new Set<S3Object>());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.folderMap, props.currentFolder]);

  if (props.currentFolderName === undefined) {
    return <></>;
  }

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof S3Object) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const downloadFileCall = (fileToDownload: S3Object) => {
    setDownloadingIndicator(true);

    getDownloadUrl(auth, fileToDownload, fileToDownload)
      .then((data) => {
        const link = document.createElement("a");
        link.download = fileToDownload.name;
        link.href = data;
        link.target = "_blank";
        link.click();
        setDownloadingIndicator(false);
      })
      .catch((err) => {
        addError(formatMessage({ id: formatErrorToMessageId(err) }, { error: JSON.stringify(err) }), "error");
        setDownloadingIndicator(false);
      });
  };

  const isChangeEvent = (obj: any): boolean => {
    return "target" in obj;
  };

  const combineFileUploadFromDiffrentSources = (acceptedFiles: any): void => {
    const files: File[] = [];
    isConfirmDialogCheckboxChecked = false;
    if (isChangeEvent(acceptedFiles)) {
      if (acceptedFiles.target.files && acceptedFiles.target.files[0]) {
        for (const file of acceptedFiles.target.files) {
          if (file instanceof File) {
            files.push(file);
          }
        }
      }
    } else {
      for (const file of acceptedFiles) {
        if (file instanceof File) {
          files.push(file);
        }
      }
    }
    handleUpload(
      files,
      filesAndResponses,
      props.currentFolder,
      formatMessage,
      addError,
      auth,
      getCurrentFolderS3Object,
      recursiveFileUpload
    );
  };

  const addNewFile = (key: string, file: File) => {
    const currentFolder = key === "/" ? "" : key;
    const currentFolderStructure = props.folderMap.get(key);

    getFolders(auth, currentFolder)
      .then((data) => {
        if (data !== null && currentFolderStructure) {
          const listOfExistingFiles = currentFolderStructure.filter(
            (existingFile) => existingFile.isFile && existingFile.s3Key === currentFolder + file.name
          );
          const newCreatedFile = data.content.filter(
            (existingFile) => existingFile.isFile && existingFile.s3Key === currentFolder + file.name
          );

          if (listOfExistingFiles.length === 0) {
            currentFolderStructure.push(newCreatedFile[0]);
          } else {
            const index = currentFolderStructure.indexOf(listOfExistingFiles[0]);
            currentFolderStructure[index] = newCreatedFile[0];
          }

          props.updateFolderMap(key, currentFolderStructure);
        }
      })
      .catch((err) => {
        addError(formatMessage({ id: formatErrorToMessageId(err) }, { error: JSON.stringify(err) }), "error");
      });
  };

  const removeFile = (key: string, file: S3Object) => {
    const currentFolderStructure = props.folderMap.get(key);

    if (currentFolderStructure) {
      const index = currentFolderStructure.findIndex((elem) => elem.s3Key === file.s3Key);
      if (index > -1) {
        currentFolderStructure.splice(index, 1); // 2nd parameter means remove one item only
      }
      props.updateFolderMap(key, currentFolderStructure);
    }
    setRowsLoadingIndicator(false);
  };

  const moveFile = (oldKey: string, oldFile: S3Object, newKey: string) => {
    const targetFolder = newKey === "/" ? "" : newKey;
    const targetFolderStructure = props.folderMap.get(newKey);

    // copy oldFile and update parentFolder and s3Key
    const newFile = Object.assign({}, oldFile);
    newFile.parentFolder = targetFolder;
    newFile.s3Key = targetFolder + oldFile.name;

    if (targetFolderStructure) {
      const existingFiles = targetFolderStructure.filter(
        (existingFile) => existingFile.isFile && existingFile.s3Key === newFile.s3Key
      );

      if (existingFiles.length === 0) {
        // datei existiert im zielpfad noch nicht
        targetFolderStructure.push(newFile);
      } else {
        // datei existiert im zielpfad, muss überschrieben werden
        const index = targetFolderStructure.indexOf(existingFiles[0]);
        targetFolderStructure[index] = newFile;
      }
      props.updateFolderMap(newKey, targetFolderStructure);
    }

    removeFile(oldKey, oldFile);
  };

  const convertToLocal = (number: string): string => {
    return currentLanguage === "de" ? number.toLocaleString().replaceAll(".", ",") : number.toLocaleString();
  };

  const isNumericColumn = (orderBy: string): boolean => {
    let result = false;
    headCells.forEach((headCell) => {
      if (headCell.id === orderBy) {
        result = headCell.numeric;
      }
    });

    return result;
  };

  const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>, row: S3Object) => {
    const result = new Set(selectedRows);
    if (selectedRows.size == 0) {
      setRowsLoadingIndicator(false);
    }
    if (selectedRows?.has(row)) {
      result.delete(row);
    } else {
      result.add(row);
    }

    setSelectedRows(result);
  };

  const handleParentCheckboxChange = () => {
    if (selectedRows.size == 0 && rows.length > 0) {
      setSelectedRows(new Set(rows));
    } else {
      setSelectedRows(new Set<S3Object>());
    }
  };

  const createRow = (row: S3Object, index: number) => {
    const labelId = `enhanced-table-checkbox-${index}`;
    return (
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={row.s3Key}
        data-testid={row.name}
        sx={{
          ["&:nth-of-type(odd)"]: {
            backgroundColor: theme.palette.grey[50],
          },
        }}
        selected={selectedRows.has(row)}>
        <TableCell sx={{ borderBottom: "none" }}>
          {rowsLoadingIndicator && selectedRows.has(row) ? (
            <CircularProgress />
          ) : (
            <Checkbox
              checked={selectedRows.has(row)}
              id={row.s3Key}
              onChange={(e) => handleCheckBoxChange(e, row)}
              data-testid={"checkbox-" + row.s3Key}
            />
          )}
        </TableCell>
        <TableCell component="th" id={labelId} scope="row" padding="none" sx={{ borderBottom: "none" }}>
          <Tooltip title={row.name} enterDelay={1000}>
            <Box sx={{ display: "inline-flex" }}>
              {isDownloadFileAllowed(row) ? (
                <Box
                  sx={{
                    textOverflow: "ellipsis",
                    maxWidth: "350px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}>
                  <Link
                    sx={{
                      ["&:hover"]: {
                        cursor: "pointer",
                        color: theme.palette.primary.main,
                      },
                      color: "black",
                      underline: "hover",
                    }}
                    onClick={() => downloadFileCall(row)}>
                    {row.name}
                  </Link>
                </Box>
              ) : (
                <Box
                  sx={{
                    color: theme.palette.grey[400],
                    textOverflow: "ellipsis",
                    maxWidth: "350px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}>
                  {row.name}
                </Box>
              )}
            </Box>
          </Tooltip>
        </TableCell>
        <TableCell sx={{ borderBottom: "none" }}>{row.fileType}</TableCell>
        <TableCell align="right" sx={{ borderBottom: "none" }}>
          {convertToLocal(Number(row.size / 1024 / 1024).toFixed(2))}
        </TableCell>
        <TableCell sx={{ borderBottom: "none" }}>{formatDate(row.lastModified)}</TableCell>
      </TableRow>
    );
  };
  function formatDate(lastModified: string): string {
    const date = new Date(lastModified);
    if (!isNaN(date.getDate())) {
      return date.toLocaleTimeString([], {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });
    } else {
      return lastModified;
    }
  }

  function recursiveFileUpload() {
    if (filesAndResponses.length > 0) {
      if (filesAndResponses[0].response.errorCode === "NON_UNIQUE_NAME" && isConfirmDialogCheckboxChecked === false) {
        setFileToUpload(filesAndResponses[0].file);
        setOpenDialog(true);
      } else {
        upload(filesAndResponses[0].file);
        filesAndResponses.splice(0, 1); //deletes first item of Array
        recursiveFileUpload();
      }
    }
  }

  function upload(file: File) {
    addLoadingFile(file);
    const uploadMethod =
      file.size < 52430000
        ? uploadFile(
            auth,
            file,
            props.currentFolder === "/" ? "" : props.currentFolder,
            getCurrentFolderS3Object(props.currentFolder)
          )
        : multiPartUpload(
            auth,
            file,
            props.currentFolder === "/" ? "" : props.currentFolder,
            getCurrentFolderS3Object(props.currentFolder),
            updateFileWithUploadProgress
          );

    uploadMethod
      .then(() => {
        addNewFile(props.currentFolder, file);
        removeLoadingFile(file);
        enqueueSnackbar(formatMessage({ id: "snackbar.uploadFileSuccess" }, { fileName: file.name }), {
          variant: "success",
        });
      })
      .catch((err) => {
        removeLoadingFile(file);
        addError(formatMessage({ id: formatErrorToMessageId(err) }, { error: JSON.stringify(err) }), "error");
      });
  }

  const closeDialog = () => {
    if (isConfirmDialogCheckboxChecked) {
      filesAndResponses.splice(0); //deletes all values in array
    }
    filesAndResponses.splice(0, 1); //deletes first item of Array
    setOpenDialog(false);
    recursiveFileUpload();
  };

  function uploadFileCall() {
    setOpenDialog(false);
    if (fileToUpload) {
      upload(fileToUpload);
    }
    filesAndResponses.splice(0, 1); //deletes first item of Array
    recursiveFileUpload();
  }

  const theme = createTheme(zebTheme);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  return (
    <>
      <div
        {...getRootProps()}
        style={{
          border: isDragActive ? "2px dashed " + theme.palette.primary.main : "2px dashed transparent",
          padding: "10px",
          borderRadius: "5px",
          overflow: "auto",
          flexDirection: "column",
          display: "flex",
        }}>
        <input {...getInputProps()} data-testid="dropzoneInput" />
        <Box sx={{ display: "flex", justifyContent: "space-between", height: "2.5rem", mb: "0.5rem" }}>
          <Typography
            sx={{
              alignItems: "center",
              display: "flex",
            }}
            variant="h6"
            id="tableTitle"
            component="div">
            <FolderOpenIcon htmlColor="#fdb900" sx={{ mr: "0.75rem" }} />
            {props.currentFolderName.replace(/\\/g, "/")}
          </Typography>
          <Box sx={{ display: "flex" }}>
            <UploadButton
              currentFolder={props.currentFolder}
              rows={rows}
              handleUpload={combineFileUploadFromDiffrentSources}
            />
            <MoveFileButton
              currentFolder={props.currentFolder}
              selectedRows={selectedRows}
              moveFile={moveFile}
              folderMap={props.folderMap}
              setFolderMap={props.setFolderMap}
              updateFolderMap={props.updateFolderMap}
              openDialog={openMoveDialog}
              setOpenDialog={setOpenMoveDialog}
            />
            <DeleteFileButton
              currentFolder={props.currentFolder}
              selectedRow={selectedRows}
              removeFile={removeFile}
              setRowsLoadingIndicator={setRowsLoadingIndicator}
            />
          </Box>
        </Box>

        {rows ? (
          <>
            <TableContainer
              sx={{ maxHeight: "100%", mb: "0.5rem", flexGrow: "1", flexDirection: "column", display: "flex" }}>
              <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="small" stickyHeader>
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  handleParentCheckboxChange={handleParentCheckboxChange}
                  numberOfSelectedRows={selectedRows.size}
                  numberOfRows={rows.length}
                />
                <TableBody>
                  {rows
                    .slice()
                    .sort(getComparator(order, orderBy, isNumericColumn(orderBy)))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(createRow)}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: 0,
                      }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{ overflow: "visible" }}
              labelRowsPerPage={formatMessage({ id: "tableFooter.rowsPerPage" })}
            />
          </>
        ) : (
          <>
            <Skeleton variant="rectangular" data-testid="noRowsSkeleton" />
          </>
        )}
        <ConfirmDialog
          openDialog={openDialog}
          closeDialog={closeDialog}
          titleMessage={{ id: "confirmDialog.existingFileName.title" }}
          textMessage={{ id: "confirmDialog.existingFileName.text" }}
          textMessageProps={{ fileName: fileToUpload ? fileToUpload.name : "" }}
          onAgree={uploadFileCall}
          handleConfirmDialogCheckboxChange={handleConfirmDialogCheckboxChange}
        />
      </div>
    </>
  );
}
