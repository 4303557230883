import { Button } from "@mui/material";
import { S3Object } from "../../../api/restModel";
import { createFolder } from "../../../api/restFacade";
import { formatErrorToMessageId } from "../../../api/errorUtil";
import { isCreateNewFolderAllowed } from "../../../api/authorizationSettings";
import { useAuth } from "react-oidc-context";
import { useIntl } from "react-intl";
import { useSnackbar } from "notistack";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import FormDialog from "../../../dialogs/FormDialog";
import React, { useState } from "react";
import useAPIError from "../../../dialogs/error/useAPIError";
import useCurrentFolder from "../../../contexts/useCurrentFolder";

interface CreateFolderButtonProps {
  currentFolder: string;
  existingFoldersInCurrentFolderPath: S3Object[] | undefined;
  addNewFolder(key: string, newFolder: S3Object): void;
}

export default function CreateFolderButton(props: CreateFolderButtonProps) {
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const { getCurrentFolderS3Object } = useCurrentFolder();
  const { enqueueSnackbar } = useSnackbar();
  const { formatMessage } = useIntl();
  const { addError } = useAPIError();
  const auth = useAuth();

  const closeDialog = () => {
    setOpenDialog(false);
  };

  const openFolderNameDialog = () => {
    setOpenDialog(true);
  };

  const createFolderCall = (folderName: string) => {
    setOpenDialog(false);
    createFolder(
      auth,
      folderName,
      props.currentFolder === "/" ? "" : props.currentFolder,
      getCurrentFolderS3Object(props.currentFolder === "/" ? "" : props.currentFolder)
    )
      .then((data) => {
        if (data) {
          props.addNewFolder(props.currentFolder, data.content as S3Object);
          enqueueSnackbar(formatMessage({ id: "snackbar.createFolderSuccess" }, { folderName }), {
            variant: "success",
          });
        }
      })
      .catch((err) => {
        addError(formatMessage({ id: formatErrorToMessageId(err) }, { error: JSON.stringify(err) }), "error");
      });
  };

  const validateFolder = (folder: string) => {
    // check if folderName contains only allowed characters
    // https://docs.aws.amazon.com/de_de/AmazonS3/latest/userguide/bucketnamingrules.html?icmpid=docs_amazons3_console
    if (!folder.match(/^[a-zA-Z0-9!_.*'()-]*$/g)) {
      return "formDialog.helpText.forbiddenCharacter";
    }

    if (
      props.existingFoldersInCurrentFolderPath &&
      props.existingFoldersInCurrentFolderPath.map((s3Object) => s3Object.name).includes(folder + "/")
    ) {
      return "formDialog.helpText.nameAlreadyExists";
    }

    return "";
  };

  return (
    <>
      <Button
        sx={{ mr: "0.5rem", mb: "0.5rem" }}
        variant="outlined"
        startIcon={<CreateNewFolderIcon />}
        component="span"
        onClick={() => openFolderNameDialog()}
        disabled={!isCreateNewFolderAllowed(getCurrentFolderS3Object(props.currentFolder))}>
        {formatMessage({ id: "button.createFolder" })}
      </Button>
      <FormDialog
        openDialog={openDialog}
        closeDialog={closeDialog}
        titleMessage={{ id: "formDialog.addFolder.title" }}
        textMessage={{ id: "formDialog.addFolder.text" }}
        textMessageProps={{}}
        onAgree={createFolderCall}
        validateValue={validateFolder}
      />
    </>
  );
}
