import { Button } from "@mui/material";
import { deleteFolder } from "../../../api/restFacade";
import { formatErrorToMessageId } from "../../../api/errorUtil";
import { isDeleteFolderAllowed } from "../../../api/authorizationSettings";
import { useAuth } from "react-oidc-context";
import { useIntl } from "react-intl";
import { useSnackbar } from "notistack";
import ConfirmDialog from "../../../dialogs/ConfirmDialog";
import DeleteFileIcon from "@mui/icons-material/Delete";
import React, { useState } from "react";
import useAPIError from "../../../dialogs/error/useAPIError";
import useCurrentFolder from "../../../contexts/useCurrentFolder";

interface DeleteButtonProps {
  currentFolder: string;
  removeFolderFromTreeMap(key: string): void;
}

export default function DeleteFolderButton(props: DeleteButtonProps) {
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const { getCurrentFolderS3Object } = useCurrentFolder();
  const { enqueueSnackbar } = useSnackbar();
  const { formatMessage } = useIntl();
  const { addError } = useAPIError();
  const auth = useAuth();

  const checkFolderForDelete = () => {
    setOpenDialog(true);
  };

  const deleteFolderCall = () => {
    setOpenDialog(false);
    deleteFolder(auth, props.currentFolder, getCurrentFolderS3Object(props.currentFolder))
      .then((result) => {
        if (result) {
          props.removeFolderFromTreeMap(props.currentFolder);
          if (result.success) {
            enqueueSnackbar(formatMessage({ id: "snackbar.deleteFolderSuccess" }, { fileName: props.currentFolder }), {
              variant: "success",
            });
          } else {
            addError(formatMessage({ id: "error.folderAlreadyDeleted" }, { fileName: props.currentFolder }), "warning");
          }
        }
      })
      .catch((err) => {
        addError(formatMessage({ id: formatErrorToMessageId(err) }, { error: JSON.stringify(err) }), "error");
      });
  };

  const closeDialog = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <Button
        sx={{ mr: "0.5rem", mb: "0.5rem" }}
        variant="outlined"
        startIcon={<DeleteFileIcon />}
        component="span"
        disabled={props.currentFolder === "/" || !isDeleteFolderAllowed(getCurrentFolderS3Object(props.currentFolder))}
        onClick={() => checkFolderForDelete()}>
        {formatMessage({ id: "button.deleteFolder" })}
      </Button>
      <ConfirmDialog
        openDialog={openDialog}
        closeDialog={closeDialog}
        titleMessage={{ id: "confirmDialog.deleteFolder.title" }}
        textMessage={{ id: "confirmDialog.deleteFolder.text" }}
        textMessageProps={{ folderName: props.currentFolder }}
        onAgree={deleteFolderCall}
      />
    </>
  );
}
