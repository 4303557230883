import { Box, Button } from "@mui/material";
import { S3Object } from "../../../api/restModel";
import { deleteFile } from "../../../api/restFacade";
import { formatErrorToMessageId } from "../../../api/errorUtil";
import { isDeleteFileAllowed } from "../../../api/authorizationSettings";
import { useAuth } from "react-oidc-context";
import { useIntl } from "react-intl";
import { useSnackbar } from "notistack";
import { useState } from "react";
import ConfirmDialog from "../../../dialogs/ConfirmDialog";
import DeleteFileIcon from "@mui/icons-material/Delete";
import useAPIError from "../../../dialogs/error/useAPIError";
import useCurrentFolder from "../../../contexts/useCurrentFolder";
import useLoadingFiles from "../../../contexts/useLoadingFiles";

interface DeleteButtonProps {
  currentFolder: string;
  selectedRow: Set<S3Object> | undefined;
  removeFile(key: string, file: S3Object): void;
  setRowsLoadingIndicator(loadingIndicatorState: boolean): void;
}

export default function DeleteFileButton(props: DeleteButtonProps) {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [filesToDelete, setFilesToDelete] = useState<Set<S3Object>>();
  const { addDeletingFile, removeDeletingFile } = useLoadingFiles();
  const { getCurrentFolderS3Object } = useCurrentFolder();

  const { enqueueSnackbar } = useSnackbar();
  const { formatMessage } = useIntl();
  const { addError } = useAPIError();
  const auth = useAuth();

  const checkFileForDelete = () => {
    if (props.selectedRow) {
      setFilesToDelete(props.selectedRow);
      setOpenDialog(true);
    }
  };

  const deleteFileCall = () => {
    props.setRowsLoadingIndicator(true);
    const errorMessages = new Array<string>();
    setOpenDialog(false);
    if (filesToDelete) {
      filesToDelete.forEach((fileToDelete) => {
        props.setRowsLoadingIndicator(true);
        addDeletingFile(fileToDelete);
        deleteFile(auth, fileToDelete)
          .then(() => {
            removeDeletingFile(fileToDelete);
            props.removeFile(props.currentFolder, fileToDelete);
            enqueueSnackbar(formatMessage({ id: "snackbar.deleteFileSuccess" }, { fileName: fileToDelete.name }), {
              variant: "success",
            });
          })
          .catch((err) => {
            removeDeletingFile(fileToDelete);
            props.selectedRow?.delete(fileToDelete);
            if (
              err.response &&
              err.response.data &&
              err.response.data.errorCode &&
              err.response.data.errorCode == "FILE_DOES_NOT_EXIST"
            ) {
              props.removeFile(props.currentFolder, fileToDelete);
            }
            if (fileToDelete) {
              errorMessages.push(
                fileToDelete.name +
                  ": " +
                  formatMessage(
                    { id: formatErrorToMessageId(err) },
                    { error: JSON.stringify(err), fileName: fileToDelete.name }
                  )
              );
              addError(errorMessages, "error");
            }
          });
      });
    }
  };

  const closeDialog = () => {
    setOpenDialog(false);
  };

  const deleteDisabled = !isDeleteFileAllowed(getCurrentFolderS3Object(props.currentFolder));
  const canFilesBeDeleted = (): boolean => {
    if (deleteDisabled) {
      return true;
    }
    return props.selectedRow?.size === 0;
  };

  return (
    <Box>
      <Button
        sx={{ mr: "0.5rem", ml: "0.5rem" }}
        variant="outlined"
        startIcon={<DeleteFileIcon />}
        component="span"
        disabled={canFilesBeDeleted()}
        onClick={() => checkFileForDelete()}>
        {formatMessage({ id: "button.deleteFile" })}
      </Button>
      <ConfirmDialog
        openDialog={openDialog}
        closeDialog={closeDialog}
        titleMessage={{ id: "confirmDialog.deleteFile.title" }}
        textMessage={{ id: "confirmDialog.deleteFile.text" }}
        onAgree={deleteFileCall}
      />
    </Box>
  );
}
