import { APIErrorProvider } from "./dialogs/error/APIErrorProvider";
import { AuthProvider, AuthProviderProps } from "react-oidc-context";
import { CurrentFolderProvider } from "./contexts/CurrentFolderProvider";
import { IAppConfig } from "./api/restModel";
import { IntlProvider } from "react-intl";
import { LoadingIndicatorProvider } from "./contexts/LoadingIndicatorProvider";
import { SnackbarProvider } from "notistack";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { WebStorageStateStore } from "oidc-client-ts";
import { axiosInstance, getAppConfig } from "./api/restFacade";
import { determineAPIUrl } from "./utils/environmentUtils";
import { makeStyles } from "@mui/styles";
import { zebStyles as parentZebStyles } from "./zebstyles";
import { useNavigate } from "react-router";
import AuthGuard from "./AuthGuard";
import CssBaseline from "@material-ui/core/CssBaseline";
import ErrorDialog from "./dialogs/error/ErrorDialog";
import MainView from "./components/MainView";
import React, { useEffect, useMemo, useRef, useState } from "react";
import messagesDE from "./i18n/de/messages.json";
import messagesEN from "./i18n/en/messages.json";
import zebTheme from "./theme/zebTheme";

const theme = createTheme();
const useStyles = makeStyles(() => ({
  ...parentZebStyles(theme),
  outerdiv: {
    height: "100%",
  },
  panel: {
    position: "absolute",
    left: "50%",
    top: "50%",
    marginLeft: "-200px",
    marginTop: "-200px",
    width: "400px",
    height: "400px",
    textAlign: "center",
    backgroundColor: theme.palette.background.paper,
    fontSize: "12px",
    fontFamily: "Open Sans,Helvetica,Arial,sans-serif",
    lineHeight: "1.66666667",
    color: theme.palette.text.secondary,
  },
}));

const POST_LOGOUT_REDIRECT_URI = "https://zeb-control.com/de-DE";

// load localization files (client-side)
const messages: { [key: string]: any } = {
  de: messagesDE,
  en: messagesEN,
};

const currentLanguage = navigator.language.split(/[-_]/)[0];

export default function App(): React.ReactElement {
  const classes = useStyles();
  const navigate = useNavigate();

  const redirectUri = useRef<string>(`${window.location.origin}/`);

  const [appConfig, setAppConfig] = useState<IAppConfig | null>(null);

  useEffect(() => {
    getAppConfig()
      .then((result) => {
        // set baseUrl for all further calls to api-prefix from app config endpoint
        axiosInstance.defaults.baseURL = `${determineAPIUrl(window.location)}${result?.["api-path-prefix"] ?? ""}`;
        setAppConfig(result);
      })
      .catch((err) => {
        console.error("Error while trying to configure authentication provider.", JSON.stringify(err));

        console.debug("Performing second attempt on legacy api baseUrl");
        axiosInstance.defaults.baseURL = determineAPIUrl(window.location, true);

        getAppConfig()
          .then((result) => {
            setAppConfig(result);
          })
          .catch((err) => {
            console.error("Error while trying to configure authentication provider with legacy api.", JSON.stringify(err));
          });
      });
  }, []);

  const authProviderConfig: AuthProviderProps = useMemo(() => {
    return {
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      authority: appConfig?.["idp-well-known-configuration"].includes(".well-known/openid-configuration")
        ? appConfig["idp-well-known-configuration"].split(".well-known/openid-configuration")[0]
        : "",
      client_id: appConfig ? appConfig["client-id"] : "",
      redirect_uri: redirectUri.current,
      automaticSilentRenew: true,
      post_logout_redirect_uri: POST_LOGOUT_REDIRECT_URI,
      onSigninCallback: (): void => {
        navigate("/");
      },
    };
  }, [appConfig, navigate]);

  return (
    <>
      <IntlProvider
        locale={currentLanguage}
        messages={messages[currentLanguage] ? messages[currentLanguage] : messages["en"]}>
        {appConfig ? (
          <AuthProvider {...authProviderConfig}>
            <AuthGuard>
              <div className={classes.outerdiv}>
                <SnackbarProvider maxSnack={3}>
                  <ThemeProvider theme={zebTheme}>
                    <CssBaseline />
                    <APIErrorProvider>
                      <LoadingIndicatorProvider>
                        <CurrentFolderProvider>
                          <MainView />
                        </CurrentFolderProvider>
                        <ErrorDialog />
                      </LoadingIndicatorProvider>
                    </APIErrorProvider>
                  </ThemeProvider>
                </SnackbarProvider>
              </div>
            </AuthGuard>
          </AuthProvider>
        ) : null}
      </IntlProvider>
    </>
  );
}
